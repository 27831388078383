var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "componente_domanda" }, [
    _vm.domanda.type == "select"
      ? _c(
          "div",
          [
            _c("q-select", {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.visible,
                  expression: "visible",
                },
              ],
              attrs: {
                "emit-value": "",
                "map-options": "",
                outlined: "",
                "virtual-scroll-slice-size": "0",
                "virtual-scroll-item-size": "0",
                disable: _vm.domanda.is_disabled,
                filled: _vm.domanda.is_disabled,
                name: _vm.domanda.name,
                options: _vm.getRisposte,
                label: _vm.domanda.label,
                error: _vm.isNotValid,
                "error-message": _vm.errorMessage,
              },
              on: {
                blur: function ($event) {
                  return _vm.onBlur()
                },
                input: function ($event) {
                  return _vm.$emit("qq-domanda-change")
                },
              },
              model: {
                value: _vm.domanda.value,
                callback: function ($$v) {
                  _vm.$set(_vm.domanda, "value", $$v)
                },
                expression: "domanda.value",
              },
            }),
          ],
          1
        )
      : _vm._e(),
    _vm.domanda.type == "input_select"
      ? _c(
          "div",
          [
            _c("q-select", {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.visible,
                  expression: "visible",
                },
              ],
              ref: "input_select",
              attrs: {
                "emit-value": "",
                "map-options": "",
                outlined: "",
                "use-input": "",
                "input-debounce": "0",
                disable: _vm.domanda.is_disabled,
                filled: _vm.domanda.is_disabled,
                name: _vm.domanda.name,
                options: _vm.getRisposte,
                label: _vm.domanda.label,
                error: _vm.isNotValid,
                "error-message": _vm.errorMessage,
              },
              on: {
                blur: function ($event) {
                  return _vm.onBlur()
                },
                input: function ($event) {
                  return _vm.$emit("qq-domanda-change")
                },
                filter: _vm.onInputSelectFilter,
              },
              scopedSlots: _vm._u(
                [
                  {
                    key: "no-option",
                    fn: function () {
                      return [
                        _c(
                          "q-item",
                          [
                            _c("q-item-section", { staticClass: "text-grey" }, [
                              _vm._v(" Nessun risultato "),
                            ]),
                          ],
                          1
                        ),
                      ]
                    },
                    proxy: true,
                  },
                ],
                null,
                false,
                2139249595
              ),
              model: {
                value: _vm.domanda.value,
                callback: function ($$v) {
                  _vm.$set(_vm.domanda, "value", $$v)
                },
                expression: "domanda.value",
              },
            }),
          ],
          1
        )
      : _vm._e(),
    _vm.domanda.type == "number"
      ? _c(
          "div",
          [
            _c("q-input", {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.visible,
                  expression: "visible",
                },
              ],
              attrs: {
                type: "number",
                outlined: "",
                name: _vm.domanda.name,
                label: _vm.domanda.label,
                error: _vm.isNotValid,
                "error-message": _vm.errorMessage,
                disable: _vm.domanda.is_disabled,
                filled: _vm.domanda.is_disabled,
                step: "1",
              },
              on: {
                blur: function ($event) {
                  return _vm.onBlur()
                },
                input: function ($event) {
                  return _vm.$emit("qq-domanda-change")
                },
              },
              scopedSlots: _vm._u(
                [
                  {
                    key: "prepend",
                    fn: function () {
                      return [
                        _vm._v(" " + _vm._s(_vm.domanda.label_prepend) + " "),
                      ]
                    },
                    proxy: true,
                  },
                  {
                    key: "append",
                    fn: function () {
                      return [
                        _vm._v(" " + _vm._s(_vm.domanda.label_append) + " "),
                      ]
                    },
                    proxy: true,
                  },
                ],
                null,
                false,
                3317047689
              ),
              model: {
                value: _vm.domanda.value,
                callback: function ($$v) {
                  _vm.$set(_vm.domanda, "value", $$v)
                },
                expression: "domanda.value",
              },
            }),
          ],
          1
        )
      : _vm._e(),
    _vm.domanda.type == "group_checkbox"
      ? _c("div", { attrs: { align: "left" } }, [
          _c(
            "fieldset",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.visible,
                  expression: "visible",
                },
              ],
              staticClass: "q-mb-md",
            },
            [
              _c("legend", [_vm._v(_vm._s(_vm.domanda.label))]),
              _c("q-option-group", {
                attrs: {
                  options: _vm.getRisposte,
                  label: _vm.domanda.label,
                  type: "checkbox",
                  name: _vm.domanda.name,
                  disable: _vm.domanda.is_disabled,
                },
                on: {
                  input: function ($event) {
                    return _vm.$emit("qq-domanda-change")
                  },
                },
                model: {
                  value: _vm.domanda.value,
                  callback: function ($$v) {
                    _vm.$set(_vm.domanda, "value", $$v)
                  },
                  expression: "domanda.value",
                },
              }),
            ],
            1
          ),
        ])
      : _vm._e(),
    _vm.domanda.type == "radio"
      ? _c("div", { attrs: { align: "left" } }, [
          _c(
            "fieldset",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.visible,
                  expression: "visible",
                },
              ],
              staticClass: "q-mb-md",
            },
            [
              _c("legend", [_vm._v(_vm._s(_vm.domanda.label))]),
              _c(
                "div",
                { attrs: { align: "left" } },
                [
                  _c("q-option-group", {
                    attrs: {
                      options: _vm.getRisposte,
                      label: _vm.domanda.label,
                      name: _vm.domanda.name,
                      type: "radio",
                      disable: _vm.domanda.is_disabled,
                      "data-cy": _vm.domanda.name,
                    },
                    on: {
                      input: function ($event) {
                        return _vm.$emit("qq-domanda-change")
                      },
                    },
                    model: {
                      value: _vm.domanda.value,
                      callback: function ($$v) {
                        _vm.$set(_vm.domanda, "value", $$v)
                      },
                      expression: "domanda.value",
                    },
                  }),
                ],
                1
              ),
            ]
          ),
        ])
      : _vm._e(),
    _vm.domanda.type == "big-radio"
      ? _c("div", { attrs: { align: "left" } }, [
          _c(
            "fieldset",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.visible,
                  expression: "visible",
                },
              ],
              staticClass: "q-mb-md",
            },
            [
              _c("div", { staticClass: "row" }, [
                _c("div", {
                  staticClass: "cl-12 col-md-8",
                  domProps: { innerHTML: _vm._s(_vm.domanda.label) },
                }),
                _c(
                  "div",
                  { staticClass: "cl-12 col-md-4", attrs: { align: "center" } },
                  [
                    _c("q-option-group", {
                      attrs: {
                        options: _vm.getRisposte,
                        label: _vm.domanda.label,
                        name: _vm.domanda.name,
                        type: "radio",
                        disable: _vm.domanda.is_disabled,
                        "data-cy": _vm.domanda.name,
                      },
                      on: {
                        input: function ($event) {
                          return _vm.$emit("qq-domanda-change")
                        },
                      },
                      model: {
                        value: _vm.domanda.value,
                        callback: function ($$v) {
                          _vm.$set(_vm.domanda, "value", $$v)
                        },
                        expression: "domanda.value",
                      },
                    }),
                  ],
                  1
                ),
              ]),
            ]
          ),
        ])
      : _vm._e(),
    _vm.domanda.type == "date"
      ? _c(
          "div",
          { attrs: { align: "center" } },
          [
            _c("q-input", {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.visible,
                  expression: "visible",
                },
              ],
              attrs: {
                outlined: "",
                mask: "##/##/####",
                label: _vm.domanda.label,
                name: _vm.domanda.name,
                error: _vm.isNotValid,
                "error-message": _vm.errorMessage,
                disable: _vm.domanda.is_disabled,
                filled: _vm.domanda.is_disabled,
              },
              on: {
                blur: function ($event) {
                  return _vm.onBlur()
                },
                input: function ($event) {
                  return _vm.$emit("qq-domanda-change")
                },
              },
              scopedSlots: _vm._u(
                [
                  {
                    key: "append",
                    fn: function () {
                      return [
                        _c(
                          "q-icon",
                          {
                            staticClass: "cursor-pointer",
                            attrs: { name: "event" },
                          },
                          [
                            _c(
                              "q-popup-proxy",
                              {
                                ref: "qDateProxy",
                                attrs: {
                                  "transition-show": "scale",
                                  "transition-hide": "scale",
                                },
                              },
                              [
                                _c(
                                  "q-date",
                                  {
                                    directives: [
                                      {
                                        name: "show",
                                        rawName: "v-show",
                                        value: _vm.visible,
                                        expression: "visible",
                                      },
                                    ],
                                    attrs: {
                                      name: _vm.domanda.name,
                                      mask: "DD/MM/YYYY",
                                    },
                                    model: {
                                      value: _vm.domanda.value,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.domanda, "value", $$v)
                                      },
                                      expression: "domanda.value",
                                    },
                                  },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "row items-center justify-end",
                                      },
                                      [
                                        _c("q-btn", {
                                          directives: [
                                            {
                                              name: "close-popup",
                                              rawName: "v-close-popup",
                                            },
                                          ],
                                          attrs: {
                                            label: "Close",
                                            color: "primary",
                                            flat: "",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ]
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ]
                    },
                    proxy: true,
                  },
                ],
                null,
                false,
                1652373815
              ),
              model: {
                value: _vm.domanda.value,
                callback: function ($$v) {
                  _vm.$set(_vm.domanda, "value", $$v)
                },
                expression: "domanda.value",
              },
            }),
          ],
          1
        )
      : _vm._e(),
    _vm.domanda.type == "text"
      ? _c(
          "div",
          { attrs: { align: "center" } },
          [
            _c("q-input", {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.visible,
                  expression: "visible",
                },
              ],
              staticClass: "textbox",
              attrs: {
                outlined: "",
                label: _vm.domanda.label,
                name: _vm.domanda.name,
                error: _vm.isNotValid,
                "error-message": _vm.errorMessage,
                disable: _vm.domanda.is_disabled,
                filled: _vm.domanda.is_disabled,
                placeholder: _vm.domanda.placeholder,
              },
              on: {
                blur: function ($event) {
                  return _vm.onBlur()
                },
                input: function ($event) {
                  return _vm.$emit("qq-domanda-change")
                },
              },
              model: {
                value: _vm.domanda.value,
                callback: function ($$v) {
                  _vm.$set(_vm.domanda, "value", $$v)
                },
                expression: "domanda.value",
              },
            }),
          ],
          1
        )
      : _vm._e(),
    _vm.domanda.type == "textarea"
      ? _c(
          "div",
          { attrs: { align: "center" } },
          [
            _c("q-input", {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.visible,
                  expression: "visible",
                },
              ],
              ref: _vm.domanda.type,
              attrs: {
                outlined: "",
                label: _vm.domanda.label,
                name: _vm.domanda.name,
                type: "textarea",
                error: _vm.isNotValid,
                "error-message": _vm.errorMessage,
                disable: _vm.domanda.is_disabled,
                filled: _vm.domanda.is_disabled,
                placeholder: _vm.domanda.placeholder,
              },
              on: {
                blur: function ($event) {
                  return _vm.onBlur()
                },
                input: function ($event) {
                  return _vm.$emit("qq-domanda-change")
                },
              },
              model: {
                value: _vm.domanda.value,
                callback: function ($$v) {
                  _vm.$set(_vm.domanda, "value", $$v)
                },
                expression: "domanda.value",
              },
            }),
          ],
          1
        )
      : _vm._e(),
    _vm.domanda.type == "email"
      ? _c(
          "div",
          { attrs: { align: "center" } },
          [
            _c("q-input", {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.visible,
                  expression: "visible",
                },
              ],
              attrs: {
                outlined: "",
                type: "email",
                prefix: "",
                label: _vm.domanda.label,
                error: _vm.isNotValid,
                "error-message": _vm.errorMessage,
                name: _vm.domanda.name,
                disable: _vm.domanda.is_disabled,
                filled: _vm.domanda.is_disabled,
                placeholder: _vm.domanda.placeholder,
                suffix: "",
              },
              on: {
                blur: function ($event) {
                  return _vm.onBlur()
                },
                input: function ($event) {
                  return _vm.$emit("qq-domanda-change")
                },
              },
              scopedSlots: _vm._u(
                [
                  {
                    key: "prepend",
                    fn: function () {
                      return [_c("q-icon", { attrs: { name: "mail" } })]
                    },
                    proxy: true,
                  },
                ],
                null,
                false,
                3194918277
              ),
              model: {
                value: _vm.domanda.value,
                callback: function ($$v) {
                  _vm.$set(_vm.domanda, "value", $$v)
                },
                expression: "domanda.value",
              },
            }),
          ],
          1
        )
      : _vm._e(),
    _vm.domanda.type == "title"
      ? _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.visible,
                expression: "visible",
              },
            ],
            attrs: { align: "center" },
          },
          [
            _c("h4", { staticClass: "q-my-sm" }, [
              _vm._v(_vm._s(_vm.domanda.label)),
            ]),
          ]
        )
      : _vm._e(),
    _vm.domanda.type == "separator"
      ? _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.visible,
                expression: "visible",
              },
            ],
            attrs: { align: "center" },
          },
          [_c("hr", { staticClass: "q-my-sm" })]
        )
      : _vm._e(),
    _vm.domanda.type == "button"
      ? _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.visible,
                expression: "visible",
              },
            ],
            attrs: { align: "center" },
          },
          [
            _c("q-btn", {
              staticClass: "q-mb-sm",
              staticStyle: { width: "200px", padding: "16px" },
              attrs: {
                color: "secondary",
                "text-color": "white",
                label: _vm.domanda.label,
                disable: _vm.domanda.is_disabled,
              },
              nativeOn: {
                click: function ($event) {
                  return _vm.btnClickEvent(_vm.domanda.label)
                },
              },
            }),
          ],
          1
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }