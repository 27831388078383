import { render, staticRenderFns } from "./QQDomanda.vue?vue&type=template&id=27381ce8&scoped=true&"
import script from "./QQDomanda.vue?vue&type=script&lang=js&"
export * from "./QQDomanda.vue?vue&type=script&lang=js&"
import style0 from "./QQDomanda.vue?vue&type=style&index=0&id=27381ce8&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "27381ce8",
  null
  
)


import QSelect from 'quasar/src/components/select/QSelect.js';
import QItem from 'quasar/src/components/item/QItem.js';
import QItemSection from 'quasar/src/components/item/QItemSection.js';
import QInput from 'quasar/src/components/input/QInput.js';
import QOptionGroup from 'quasar/src/components/option-group/QOptionGroup.js';
import QIcon from 'quasar/src/components/icon/QIcon.js';
import QPopupProxy from 'quasar/src/components/popup-proxy/QPopupProxy.js';
import QDate from 'quasar/src/components/date/QDate.js';
import QBtn from 'quasar/src/components/btn/QBtn.js';
import QRadio from 'quasar/src/components/radio/QRadio.js';import ClosePopup from 'quasar/src/directives/ClosePopup.js';
import qInstall from "../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(component, 'components', {QSelect,QItem,QItemSection,QInput,QOptionGroup,QIcon,QPopupProxy,QDate,QBtn,QRadio});qInstall(component, 'directives', {ClosePopup});
/* hot reload */
if (module.hot) {
  var api = require("/builds/navert/abynext-frontend/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('27381ce8')) {
      api.createRecord('27381ce8', component.options)
    } else {
      api.reload('27381ce8', component.options)
    }
    module.hot.accept("./QQDomanda.vue?vue&type=template&id=27381ce8&scoped=true&", function () {
      api.rerender('27381ce8', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/QQDomanda.vue"
export default component.exports